<style scoped>
.ivu-table .demo-table-info-row td {
    background-color: #2db7f5;
    color: #fff;
    padding-left: 3px !important;
    padding-right: 3px !important;
}
.ivu-table td.demo-table-info-column {
    background-color: #2db7f5;
    color: #fff;
}
.cursor-pointer {
    cursor: pointer;
}
</style>
<template>
    <div>
        <div class="demo-spin-article">
            <div class="row mb-3">
                <div class="col-md-4">
                    <label class="form-col-label control-label required">{{
                        $t("journalEntry.date")
                    }}</label>
                    <DatePicker
                        type="date"
                        :value="model.transaction_date"
                        style="width:100%"
                        placeholder="DD-MM-YYYY"
                        format="dd-MM-yyyy"
                        @on-change="onTransactionDate"
                        ref="transaction_date"
                        :disabled="isUpdate"
                    ></DatePicker>
                    <div
                        class="invalid-feedback"
                        v-if="errors.has('transaction_date')"
                    >
                        {{ errors.first("transaction_date") }}
                    </div>
                </div>
                <div class="col-md-4">
                    <label class="form-col-label control-label required">{{
                        $t("journalEntry.currency")
                    }}</label>
                    <select
                        v-model.number="model.currency_id"
                        class="form-select"
                        :class="{ 'is-invalid': errors.has('currency_id') }"
                        @change="getCoaSetup"
                        @input="model.details = []"
                    >
                        <option
                            :value="null"
                            disabled
                            selected
                            style="display: none;"
                            >{{ $t("select") }}</option
                        >
                        <option
                            v-for="(c, index) in currencies"
                            :key="index"
                            :value="c.currency_id"
                            >{{ c.currency_name_en }} |
                            {{ c.currency_code }}</option
                        >
                    </select>
                    <div
                        class="invalid-feedback"
                        v-if="errors.has('currency_id')"
                    >
                        {{ errors.first("currency_id") }}
                    </div>
                </div>
                <div class="col-md-4">
                    <label class="form-col-label control-label">{{
                        $t("journalEntry.fxRate")
                    }}</label>
                    <input
                        type="number"
                        v-model.number="model.fx_rate"
                        class="form-control"
                        :class="{ 'is-invalid': errors.has('fx_rate') }"
                        readonly
                    />
                    <div class="invalid-feedback" v-if="errors.has('fx_rate')">
                        {{ errors.first("fx_rate") }}
                    </div>
                </div>
            </div>
            <div class="row tw-mb-6">
                <div class="col-md-6">
                    <label class="form-col-label control-label">{{
                        $t("journalEntry.referenceNo")
                    }}</label>
                    <input
                        type="text"
                        v-model="model.reference_no"
                        class="form-control"
                        :class="{ 'is-invalid': errors.has('reference_no') }"
                    />
                    <div
                        class="invalid-feedback"
                        v-if="errors.has('reference_no')"
                    >
                        {{ errors.first("reference_no") }}
                    </div>
                </div>
                <div class="col-md-6">
                    <label class="form-col-label control-label">{{
                        $t("journalEntry.remarks")
                    }}</label>
                    <textarea
                        v-model="model.remarks"
                        rows="1"
                        class="form-control"
                        :class="{ 'is-invalid': errors.has('remarks') }"
                    ></textarea>
                    <div class="invalid-feedback" v-if="errors.has('remarks')">
                        {{ errors.first("remarks") }}
                    </div>
                </div>
            </div>
            <div class="tw-mb-6">
                <fieldset>
                    <legend class="text-blue">
                        {{ $t("journalEntry.chartOfAccount") }}
                        <Tooltip placement="top">
                            <Icon
                                type="ios-information-circle-outline"
                                size="17"
                            />
                            <div slot="content">
                                <div class="tw-text-xs">
                                    Double click on table row or click icon
                                </div>
                                <span class="tw-text-xs"
                                    >plus to add item in to item details.</span
                                >
                            </div>
                        </Tooltip>
                    </legend>
                    <div class="col-md-12">
                        <div class="tw-flex tw-justify-between tw-mb-3 tw-mt-2">
                            <select
                                v-model.number="model.acc_cat_id"
                                class="form-select"
                                style="width: 309px"
                                @change="getCoaSetup"
                            >
                                <option
                                    :value="null"
                                    disabled
                                    selected
                                    style="display: none;"
                                    >{{
                                        $t("journalEntry.filterByCoaCategory")
                                    }}</option
                                >
                                <option
                                    v-for="(cat, index) in coaCategories"
                                    :key="index"
                                    :value="cat.acc_cat_id"
                                    >{{ cat.acc_cat_name_en }} |
                                    {{ cat.acc_cat_type }}
                                </option>
                            </select>
                            <Input
                                v-model="model.search"
                                search
                                :placeholder="$t('journalEntry.searchCoaSetup')"
                                style="width: 309px"
                            />
                        </div>
                        <Table
                            height="150"
                            border
                            :columns="columns"
                            :data="coaSetupLists"
                            size="small"
                            :loading="coaSetupLoading"
                            @on-row-dblclick="onAddItem"
                            :row-class-name="rowClassName"
                            stripe
                        >
                            <template slot-scope="{ row }" slot="currency_id">
                                {{
                                    row.currency
                                        ? row.currency.currency_name_en +
                                          "|" +
                                          row.currency.currency_code
                                        : ""
                                }}
                            </template>
                            <template slot-scope="{ row }" slot="action">
                                <Tooltip
                                    placement="top"
                                    :transfer="true"
                                    :content="$t('add')"
                                >
                                    <a
                                        href="#"
                                        class="text-blue"
                                        @click.prevent="onAddItem(row)"
                                    >
                                        <Icon
                                            type="ios-add-circle-outline"
                                            size="20"
                                        />
                                    </a>
                                </Tooltip>
                            </template>
                        </Table>
                    </div>
                </fieldset>
            </div>
            <div class="mt-3 mb-3">
                <fieldset>
                    <legend class="text-blue">
                        {{ $t("journalEntry.itemDetail") }}
                        <Tooltip placement="top">
                            <Icon
                                type="ios-information-circle-outline"
                                size="17"
                            />
                            <div slot="content">
                                <div class="tw-text-xs">
                                    Journal entry item details.
                                </div>
                            </div>
                        </Tooltip>
                    </legend>
                    <div
                        class="tw-mt-2 overflow-auto tw-max-h-40"
                        style="min-height: 100px;"
                    >
                        <div class="table-ui-detail">
                            <table>
                                <thead>
                                    <tr>
                                        <th width="10%">
                                            {{
                                                $t("journalEntry.accountNumber")
                                            }}
                                        </th>
                                        <th width="25%">
                                            {{ $t("journalEntry.accountName") }}
                                        </th>
                                        <th class="text-center" width="5%">
                                            {{
                                                $t("journalEntry.currencyCode")
                                            }}
                                        </th>
                                        <th class="text-center" width="5%">
                                            {{
                                                $t("journalEntry.displayOrder")
                                            }}
                                        </th>
                                        <th class="text-center" width="12%">
                                            {{ $t("journalEntry.debitAmount") }}
                                        </th>
                                        <th class="text-center" width="12%">
                                            {{
                                                $t("journalEntry.creditAmount")
                                            }}
                                        </th>
                                        <th>
                                            {{ $t("journalEntry.remarks") }}
                                        </th>
                                        <th class="text-center" width="5%">
                                            {{ $t("actions") }}
                                        </th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr
                                        v-for="(detail, index) in items"
                                        :key="index"
                                    >
                                        <td class="tw-whitespace-nowrap">
                                            {{ detail.account_number }}
                                        </td>
                                        <td class="tw-whitespace-nowrap">
                                            {{ detail.account_name_en }}
                                        </td>
                                        <td
                                            class="text-center tw-whitespace-nowrap"
                                        >
                                            {{ detail.currency_code }}
                                        </td>
                                        <td
                                            class="text-center tw-whitespace-nowrap"
                                        >
                                            <input type="number" v-model.number="detail.display_order" class="form-control form-control-sm text-center"/>
                                        </td>
                                        <td class="text-center">
                                            <Poptip trigger="focus">
                                                <Input
                                                    v-model.number="
                                                        detail.debit_amount
                                                    "
                                                    @on-keypress="() => detail.credit_amount = null"
                                                    :placeholder="
                                                        $t(
                                                            'loanCurrency.enterNumber'
                                                        )
                                                    "
                                                    style="width: 100%"
                                                    :class="{
                                                        'ivu-form-item-error': errors.has(
                                                            'details.' +
                                                                index +
                                                                '.debit_amount'
                                                        )
                                                    }"
                                                    class="tw-text-right"
                                                >
                                                    <i
                                                        class="fas fa-dollar-sign"
                                                        slot="prefix"
                                                        v-if="
                                                            model.currency_id ==
                                                                1
                                                        "
                                                    ></i>
                                                    <i
                                                        class="far fa-registered"
                                                        slot="prefix"
                                                        v-if="
                                                            model.currency_id ==
                                                                2
                                                        "
                                                    ></i>
                                                    <i
                                                        class="fas fa-bold"
                                                        slot="prefix"
                                                        v-if="
                                                            model.currency_id ==
                                                                3
                                                        "
                                                    ></i>
                                                </Input>
                                                <div slot="content">
                                                    {{
                                                        detail.debit_amount
                                                            ? formatNumber(
                                                                  detail.debit_amount,
                                                                  model.currency_id
                                                              )
                                                            : $t(
                                                                  "loanCurrency.enterNumber"
                                                              )
                                                    }}
                                                </div>
                                            </Poptip>
                                        </td>
                                        <td class="text-center">
                                            <Poptip trigger="focus">
                                                <Input
                                                    v-model.number="
                                                        detail.credit_amount
                                                    "
                                                    @on-keypress="() => detail.debit_amount = null"
                                                    :placeholder="
                                                        $t(
                                                            'loanCurrency.enterNumber'
                                                        )
                                                    "
                                                    style="width: 100%"
                                                    :class="{
                                                        'ivu-form-item-error': errors.has(
                                                            'details.' +
                                                                index +
                                                                '.credit_amount'
                                                        )
                                                    }"
                                                    class="tw-text-right"
                                                >
                                                    <i
                                                        class="fas fa-dollar-sign"
                                                        slot="prefix"
                                                        v-if="
                                                            model.currency_id ==
                                                                1
                                                        "
                                                    ></i>
                                                    <i
                                                        class="far fa-registered"
                                                        slot="prefix"
                                                        v-if="
                                                            model.currency_id ==
                                                                2
                                                        "
                                                    ></i>
                                                    <i
                                                        class="fas fa-bold"
                                                        slot="prefix"
                                                        v-if="
                                                            model.currency_id ==
                                                                3
                                                        "
                                                    ></i>
                                                </Input>
                                                <div slot="content">
                                                    {{
                                                        detail.credit_amount
                                                            ? formatNumber(
                                                                  detail.credit_amount,
                                                                  model.currency_id
                                                              )
                                                            : $t(
                                                                  "loanCurrency.enterNumber"
                                                              )
                                                    }}
                                                </div>
                                            </Poptip>
                                        </td>
                                        <td class="text-center">
                                            <input
                                                v-model="detail.remarks"
                                                class="form-control form-control-sm"
                                                :class="{
                                                    'is-invalid': errors.has(
                                                        'details.' +
                                                            index +
                                                            '.remarks'
                                                    )
                                                }"
                                            />
                                            <div
                                                class="invalid-feedback"
                                                v-if="
                                                    errors.has(
                                                        'details.' +
                                                            index +
                                                            '.remarks'
                                                    )
                                                "
                                            >
                                                {{
                                                    errors.first(
                                                        "details." +
                                                            index +
                                                            ".remarks"
                                                    )
                                                }}
                                            </div>
                                        </td>
                                        <td class="text-center">
                                            <Tooltip
                                                placement="top"
                                                :transfer="true"
                                                :content="$t('remove')"
                                            >
                                                <a
                                                    href="#"
                                                    class="text-danger"
                                                    @click.prevent="
                                                        removeRow(detail)
                                                    "
                                                >
                                                    <Icon
                                                        type="ios-remove-circle-outline"
                                                        size="20"
                                                    />
                                                </a>
                                            </Tooltip>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                    <div class="table-ui-detail">
                        <table>
                            <tbody>
                                <tr v-if="model.details.length > 0">
                                    <th
                                        width="39%"
                                        colspan="3"
                                        class="tw-text-right tw-font-bold text-blue tw-text-lg"
                                    >
                                        {{ $t("journalEntry.total") }} :
                                    </th>
                                    <th
                                        width="18%"
                                        class="tw-text-right tw-font-bold text-blue"
                                    >
                                        {{
                                            formatCurrencyWithCode(
                                                totalDebit,
                                                currency
                                            )
                                        }}
                                    </th>
                                    <th
                                        width="18%"
                                        class="tw-text-right tw-font-bold text-blue"
                                    >
                                        {{
                                            formatCurrencyWithCode(
                                                totalCredit,
                                                currency
                                            )
                                        }}
                                    </th>
                                    <th
                                        colspan="2"
                                        class="tw-text-center tw-text-xs text-danger"
                                    >
                                        <small
                                            class="validate text-danger"
                                            v-if="errors.has('invalid_total')"
                                        >
                                            {{ errors.first("invalid_total") }}
                                        </small>
                                    </th>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </fieldset>
                <div class="validate text-danger" v-if="errors.has('details')">
                    {{ errors.first("details") }}
                </div>
            </div>
            <div slot="footer">
                <div class="tw-flex tw-justify-end tw-space-x-2 tw-sh">
                    <ts-button
                        @click.prevent="() => $emit('cancel')"
                        class="btn-gray"
                    >
                        {{ $t("cancel") }}</ts-button
                    >
                    <ts-button
                        color="primary"
                        v-if="!isUpdate"
                        outline
                        :waiting="waiting_new"
                        :disabled="waiting"
                        @click.prevent="onSaveAddNew"
                    >
                        {{ $t("saveAddNew") }}</ts-button
                    >
                    <ts-button
                        color="primary"
                        v-if="!isUpdate"
                        :waiting="waiting"
                        :disabled="waiting_new"
                        @click.prevent="onSave"
                    >
                        {{ $t("save") }}</ts-button
                    >
                    <ts-button
                        v-if="isUpdate"
                        color="primary"
                        @click.prevent="onUpdate"
                        :waiting="waiting"
                    >
                        {{ $t("update") }}</ts-button
                    >
                </div>
            </div>
        </div>
        <Spin size="large" fix v-if="loading"> </Spin>
    </div>
</template>

<script>
import { Errors } from "form-backend-validation";
import { mapActions, mapGetters, mapState } from "vuex";
import { isEmpty, debounce, orderBy } from "lodash";
import moment from "moment";

export default {
    name: "journalEntryForm",
    data() {
        return {
            errors: new Errors(),
            loading: false,
            waiting: false,
            waiting_new: false,
            coaSetupLoading: false,
            coaSetups: [],
            model: {
                transaction_date: moment().format("DD-MM-YYYY"),
                currency_id: null,
                fx_rate: null,
                remarks: null,
                reference_no: null,
                search: null,
                acc_cat_id: null,
                details: []
            }
        };
    },
    computed: {
        ...mapState("accounting/journalEntry", [
            "edit_data",
            "coaCategories",
            "currencies"
        ]),
        ...mapGetters(["formatNumber", "formatCurrencyWithCode"]),
        isUpdate() {
            return !isEmpty(this.edit_data);
        },
        currency() {
            if (this.model.currency_id) {
                return this.currencies
                    .filter(c => c.currency_id == this.model.currency_id)
                    .reduce(function(a, b) {
                        return a.concat(b);
                    });
            }
            return null;
        },
        totalDebit() {
            if (this.model.details.length > 0) {
                return this.model.details.reduce(
                    (n, { debit_amount }) => n + debit_amount,
                    0
                );
            }
            return 0;
        },
        totalCredit() {
            if (this.model.details.length > 0) {
                return this.model.details.reduce(
                    (n, { credit_amount }) => n + credit_amount,
                    0
                );
            }
            return 0;
        },
        columns() {
            return [
                {
                    title: this.$t("journalEntry.accountNumber"),
                    key: "account_number",
                    fixed: "left",
                    width: 100
                },
                {
                    title: this.$t("journalEntry.accountName"),
                    key: "account_name_en"
                },
                {
                    title: this.$t("journalEntry.currency"),
                    slot: "currency_id",
                    align: "center",
                    width: 120
                },
                {
                    title: this.$t("actions"),
                    slot: "action",
                    fixed: "right",
                    align: "center",
                    className: "tw-space-x-2",
                    width: 85
                }
            ];
        },
        coaSetupLists() {
            return orderBy(this.coaSetups, ['currency_id']);
        },
        items() {
            return orderBy(this.model.details, ['display_order']);
        }
    },
    methods: {
        ...mapActions("accounting/journalEntry", [
            "getCurrency",
            "getCoaCategory"
        ]),
        async fetchResource() {
            this.loading = true;
            await this.getCurrency();
            this.model.currency_id = this.$store.state.authUser.sys_setting[0].default_currency_id;
            await this.getCoaCategory();
            this.getCoaSetup();
            this.setEditData();
            this.loading = false;
        },
        onTransactionDate(date) {
            this.model.transaction_date = date;
        },
        rowClassName() {
            return "cursor-pointer";
        },
        cellClassName() {
            return "demo-table-info-column";
        },
        onAddItem(row) {
            this.model.details.unshift({
                transaction_id: null,
                account_id: row.account_id,
                account_number: row.account_number,
                account_name_en: row.account_name_en,
                currency_code: row.currency ? row.currency.currency_code : "",
                remarks: null,
                debit_amount: null,
                credit_amount: null,
                display_order: this.items.length + 1
            });
        },
        removeRow(item) {
            this.model.details.splice(this.model.details.indexOf(item), 1);
        },
        getCoaSetup() {
            this.setFxRate();
            this.coaSetupLoading = true;
            this.$store
                .dispatch("accounting/journalEntry/getCoaSetup", this.model)
                .then(response => {
                    this.coaSetups = response.data;
                })
                .catch(error => {
                    this.notice({ type: "error", text: error.message });
                })
                .finally(() => {
                    this.coaSetupLoading = false;
                });
        },
        setFxRate() {
            let currency = this.$store.state.authUser.sys_setting.filter(
                s => s.currency_id_from == this.model.currency_id
            );
            if (currency.length > 0) {
                this.model.fx_rate = currency.reduce(function(a, b) {
                    return a.concat(b);
                }).fx_rate;
            } else {
                this.model.fx_rate = 1;
            }
            if (!this.model.currency_id) {
                this.model.fx_rate = null;
            }
        },
        onSaveAddNew() {
            this.errors = new Errors();
            this.waiting_new = true;
            this.$store
                .dispatch("accounting/journalEntry/store", this.model)
                .then(response => {
                    this.notice({ type: "success", text: response.message });
                    this.clearInput();
                    this.$emit("fetchData");
                })
                .catch(error => {
                    this.errors = new Errors(error.errors);
                    this.notice({ type: "error", text: error.message });
                })
                .finally(() => {
                    this.waiting_new = false;
                });
        },
        onSave() {
            this.errors = new Errors();
            this.waiting = true;
            this.$store
                .dispatch("accounting/journalEntry/store", this.model)
                .then(response => {
                    this.notice({ type: "success", text: response.message });
                    this.$emit("cancel");
                    this.$emit("fetchData");
                })
                .catch(error => {
                    this.errors = new Errors(error.errors);
                    this.notice({ type: "error", text: error.message });
                })
                .finally(() => {
                    this.waiting = false;
                });
        },
        onUpdate() {
            this.waiting = true;
            this.$store
                .dispatch("accounting/journalEntry/update", {
                    id: this.edit_data.transaction_id,
                    data: this.model
                })
                .then(response => {
                    this.notice({ type: "success", text: response.message });
                    this.$emit("cancel");
                    this.$emit("fetchData");
                })
                .catch(error => {
                    this.errors = new Errors(error.errors);
                    this.notice({ type: "error", text: error.message });
                })
                .finally(() => {
                    this.waiting = false;
                });
        },
        async setEditData() {
            this.errors = new Errors();
            this.$nextTick(function() {
                this.$refs.transaction_date.focus();
            });
            if (!isEmpty(this.edit_data)) {
                this.model.currency_id = this.edit_data.currency_id;
                await this.getCoaSetup();
                this.model.currency_id = this.edit_data.currency_id;
                this.model.fx_rate = this.edit_data.fx_rate;
                this.model.reference_no = this.edit_data.reference_no;
                this.model.remarks = this.edit_data.remarks;
                this.edit_data.details.forEach(detail => {
                    this.model.details.unshift({
                        transaction_id: detail.transaction_id,
                        account_id: detail.account_id,
                        account_number: detail.account.account_number,
                        account_name_en: detail.account.account_name_en,
                        currency_code: detail.account.currency
                            ? detail.account.currency.currency_code
                            : "",
                        remarks: detail.remarks,
                        debit_amount: detail.debit_amount,
                        credit_amount: detail.credit_amount,
                        display_order: detail.display_order,
                    });
                });
            }
        },
        clearInput() {
            this.model.transaction_date = moment().format("DD-MM-YYYY");
            this.model.currency_id = null;
            this.model.fx_rate = null;
            this.model.reference_no = null;
            this.model.remarks = null;
            this.model.acc_cat_id = null;
            this.model.search = null;
            this.model.details = [];
            this.getCoaSetup();
        },
        notice(not) {
            this.$Notice[not.type]({
                title: "JOURNAL ENTRY",
                desc: not.text
            });
        }
    },
    watch: {
        "model.search": debounce(function() {
            this.getCoaSetup();
        }, 500)
    }
};
</script>
